<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="关键字">
          <el-input
            placeholder="请输入"
            v-model="searchData.keywordsValue"
            clearable
            class="input-with-select"
            size="small"
          >
            <el-select
              v-model="searchData.keywordsName"
              slot="prepend"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in searchTypeCount"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="search"
            >搜索</el-button
          >
          <el-button
            type="info"
            size="small"
            icon="el-icon-refresh"
            @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="header-btn">
      <div></div>
      <div>
        <el-dropdown
          v-if="show_dropdown"
          v-checkbtn="isShowBtn(AUTH_BTN.finance_count_list_handle_more)"
          style="margin-right: 20px"
          size="medium"
          @command="handleCommand"
        >
          <el-button size="small" type="primary"
            >订单合并处理<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="offAmount">还款调整</el-dropdown-item>
            <el-dropdown-item command="offLine">线下还款</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button
          v-checkbtn="isShowBtn(AUTH_BTN.finance_count_list_export)"
          style="margin-bottom: 20px"
          type="primary"
          icon="el-icon-download"
          size="small"
          @click="downloadExcel"
          >导出</el-button
        >
      </div>
    </div>

    <tp-table
      :isCheckBox="true"
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
      @handleSelectionChange="handleSelectionChange"
    />
    <!-- 弹窗 -->
    <OffAmount
      v-if="dialogType == 'offAmount'"
      ref="offAmount"
      :orderNo="is_mult ? orderNoList : orderNo"
      @update="getList"
    ></OffAmount>
    <OffLine
      v-if="dialogType == 'offLine'"
      ref="offLine"
      :orderNo="is_mult ? orderNoList : orderNo"
      @update="getList"
    ></OffLine>
    <!-- 设备监管 -->
    <el-dialog title="设备监管" :visible.sync="show_lock" width="800px">
      <el-form ref="formLock" :model="lockData" label-width="130px">
        <div class="lock-info">
          <span>订单号：{{ lockData.orderNo }}</span>
          <span>客户姓名：{{ lockData.memberName }}</span>
          <span>手机号：{{ lockData.memberMobile }}</span>
        </div>
        <el-form-item label="上次锁机日：">{{ lockData.beforeLockDate }}</el-form-item>
        <el-form-item label="设备状态：">
          <el-select
            style="width: 220px"
            v-model="lockData.lockStatus"
            placeholder="请选择"
            clearable
            size="mini"
          >
            <el-option label="未锁机" :value="0"></el-option>
            <el-option label="待锁机" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="lockData.lockStatus == 1" label="待锁机日期：">
          <el-date-picker
            style="width: 220px"
            v-model="lockData.lockDate"
            value-format="yyyy-MM-dd"
            size="mini"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <span style="color: #f56c6c; margin-left: 15px"
            >（ 注意：只能选当天及以后日期 ）</span
          >
        </el-form-item>
        <el-form-item v-if="lockData.lockStatus == 1" label="延期锁机服务费：">
          <el-input-number
            style="width: 220px"
            v-model="lockData.lockFee"
            size="small"
            :controls="false"
            :min="0.01"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_lock = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submitLock" size="mini"
          >提 交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from "@/mixins/authBtnMixin";
import { searchTypeCount } from "@/enum/dict/index.js";
import { getFinanceOrderListAPI, exportAPI, submitLockAPI } from "./api";
import OffAmount from "./reduce-pay.vue";
import OffLine from "./offline-pay.vue";

const columns = [
  {
    label: "用户信息",
    prop: "member",
    minWidth: "200",
    customRender(h, row) {
      return (
        <div>
          <p>姓名：{row["member"]?.["memberName"]}</p>
          <p>手机号：{row["member"]?.["mobile"]}</p>
        </div>
      );
    },
  },
  {
    label: "订单号",
    prop: "orderNo",
    minWidth: "260",
    customRender(h, row) {
      return (
        <div>
          <div>
            {row["orderNo"]}
            <el-tag
              v-show={!!row["enableAlipayFundAuth"]}
              type="danger"
              size="mini"
              style="margin-left: 5px"
            >
              免押
            </el-tag>
          </div>
        </div>
      );
    },
  },
  {
    label: "审核",
    prop: "reviewUserName",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["reviewUser"]?.["userName"]}</p>;
    },
  },
  {
    label: "设备监管",
    prop: "order",
    minWidth: "200",
    customRender(h, row) {
      return (
        <p>
          <div>
            锁机状态：
            <el-tag v-show={row["lockStatus"] == 0} type="success" size="mini">
              未上锁
            </el-tag>
            <el-tag v-show={row["lockStatus"] == 1} type="warning" size="mini">
              待上锁
            </el-tag>
            <el-tag v-show={row["lockStatus"] == 2} type="danger" size="mini">
              已上锁
            </el-tag>
          </div>
          <div>
            待锁机日期：
            {row["lockDate"] || "无"}
          </div>
        </p>
      );
    },
  },
  {
    label: "成本",
    prop: "costPrice",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["costPrice"]}</p>;
    },
  },
  {
    label: "下单时间",
    prop: "createTime",
    minWidth: "180",
    customRender(h, row) {
      return <p>{row["createTime"]}</p>;
    },
  },
  {
    label: "订单状态",
    prop: "statusName",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["statusName"]}</p>;
    },
  },
  {
    label: "操作",
    minWidth: "220",
    fixed: "right",
    customRender(h, row) {
      return (
        <div>
          <el-link
            style="margin:0 5px;"
            disabled={!this.isShowBtn(this.AUTH_BTN.finance_count_list_reduce)}
            underline={false}
            type="primary"
            onClick={() => this.handleShowDialog("offAmount", row["orderNo"])}
          >
            还款调整
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.finance_count_list_offline)}
            style="margin:0 5px;"
            underline={false}
            type="primary"
            onClick={() => this.handleShowDialog("offLine", row["orderNo"])}
          >
            线下还款
          </el-link>
          <el-link
            v-show={row.status == 6 || row.status == 7}
            disabled={!this.isShowBtn(this.AUTH_BTN.finance_count_list_lock)}
            underline={false}
            type="primary"
            onClick={() => this.handleLock(row)}
          >
            设备监管
          </el-link>
        </div>
      );
    },
  },
];
export default {
  name: "List",
  mixins: [authBtnMixin],
  components: { OffAmount, OffLine },
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        keywordsName: "",
        keywordsValue: "",
      },
      searchTypeCount,
      dialogType: "",
      orderNo: [],
      orderNoList: [],
      select_list: [],
      is_search: false,
      is_mult: false,
      // 设备监管
      lockData: {
        orderNo: "",
        memberName: "",
        memberMobile: "",
        lockStatus: 0,
        beforeLockDate: "",
        lockDate: "",
        lockFee: undefined,
      },
      show_lock: false,
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return time.getTime() < date;
        },
      },
    };
  },
  computed: {
    show_dropdown() {
      if (this.is_search) {
        return true;
      } else {
        return false;
      }
    },
  },
  provide() {
    return {
      context: this,
    };
  },
  mounted() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    pageSize() {
      this.getList();
    },
  },
  methods: {
    // 设备监管
    handleLock(row) {
      this.lockData = {
        orderNo: row.orderNo,
        memberName: row.member.memberName,
        memberMobile: row.member.mobile,
        lockStatus: 1,
        beforeLockDate: row.lockDate,
        lockDate: '',
        lockFee: undefined,
      };
      this.show_lock = true;
    },
    // 提交设备监管
    submitLock() {
      const { orderNo, lockStatus, lockDate, lockFee } = this.lockData;
      let data = {};
      if (lockStatus == 1) {
        if (!lockDate) return this.$message.error("请选择待锁机日期！");
        if (!lockFee) return this.$message.error("请填写延期锁机服务费！");
        data = { orderNo, lockStatus, lockDate, lockFee };
      } else {
        data = { orderNo, lockStatus };
      }
      submitLockAPI(data).then(() => {
        this.$message.success("操作成功");
        this.getList();
        this.show_lock = false;
      });
    },
    handleCommand(e) {
      if (this.orderNoList.length == 0) return this.$message.error("请选择账单！");
      this.is_mult = true;
      this.dialogType = e;
      this.$nextTick(() => {
        this.$refs[this.dialogType].outerVisible = true;
        if (e == "offLine") {
          this.$refs[this.dialogType].payAmount = "";
        }
        this.$refs[this.dialogType].getOrderBillList();
      });
    },
    handleSelectionChange(val) {
      this.orderNoList = val.map((item) => {
        return item.orderNo;
      });
    },
    search() {
      this.getList();
    },
    async getList() {
      const { keywordsName, keywordsValue } = this.searchData;
      if (!keywordsName && keywordsValue) return this.$message.error("请选择搜索类型！");
      let params = { page: this.currentPage, pageSize: this.pageSize };
      if (keywordsName) params[keywordsName] = keywordsValue;
      const res = await getFinanceOrderListAPI(params);
      this.list = res.list || [];
      this.total = res.total || 0;
      if (keywordsName == "memberName" && keywordsValue) {
        this.is_search = true;
      } else {
        this.is_search = false;
      }
    },
    downloadExcel(orderNo) {
      const { keywordsName, keywordsValue } = this.searchData;
      let params = {};
      if (keywordsName) params[keywordsName] = keywordsValue;
      exportAPI(params);
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData);
      this.getList();
    },
    handleShowDialog(type, orderNo) {
      this.is_mult = false;
      this.dialogType = type;
      this.orderNo = [orderNo];
      this.$nextTick(() => {
        this.$refs[this.dialogType].outerVisible = true;
        if (type == "offLine") {
          this.$refs[this.dialogType].payAmount = "";
        }
        this.$refs[this.dialogType].getOrderBillList();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 350px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      .el-select .el-input {
        width: 100px;
      }
      .input-with-select {
        vertical-align: middle;
      }
      .input-with-select .el-input-group__prepend {
        background-color: #fff;
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
  .lock-info {
    font-size: 16px;
    font-weight: 500;
    margin: 20px;
    >span {
      margin: 0 20px;
    }
  }
}
</style>
